<!-- SplashScreen.vue -->
<template>
    <div v-if="isVisible" class="splash-screen">
      <div class="splash-loader">
        <div class="splash-cube1"></div>
        <div class="splash-cube2"></div>
        <div class="splash-cube4"></div>
        <div class="splash-cube3"></div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { ref, onMounted, onBeforeUnmount } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  
  export default {
    setup() {
      const isVisible = ref(true);
      const route = useRoute();
      const router = useRouter();
  
      const hideLoader = () => {
        setTimeout(() => (isVisible.value = false), 1000); // Adjust timing as needed
      };
  
      onMounted(() => {
        hideLoader();
      });
  
      router.beforeEach((to, from, next) => {
        isVisible.value = true; // Show loader on route change
        next();
      });
  
      router.afterEach(() => {
        hideLoader(); // Hide loader after navigation completes
      });
  
      return { isVisible };
    },
  };
  </script>
  
  <style scoped>
.splash-screen {
  position: fixed; /* Ensure it stays on top of everything and doesn't scroll with content */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 1); /* Optional: Light overlay effect */
  z-index: 9999; /* Make sure it's above other content */
}

.splash-screen .splash-loader {
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg);
}

.splash-screen .splash-loader > div {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
}

.splash-screen .splash-loader > div:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #495057;
  animation: splash-loader-anim 2.4s infinite linear both;
  transform-origin: 100% 100%;
}

.splash-screen .splash-loader > div.splash-cube2 {
  transform: scale(1.1) rotateZ(90deg);
}

.splash-screen .splash-loader > div.splash-cube2:before {
  animation-delay: 0.3s;
}

.splash-screen .splash-loader > div.splash-cube3 {
  transform: scale(1.1) rotateZ(180deg);
}

.splash-screen .splash-loader > div.splash-cube3:before {
  animation-delay: 0.6s;
}

.splash-screen .splash-loader > div.splash-cube4 {
  transform: scale(1.1) rotateZ(270deg);
}

.splash-screen .splash-loader > div.splash-cube4:before {
  animation-delay: 0.9s;
}

@keyframes splash-loader-anim {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
  </style>
