import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';

const routes = [
    {
        path: '/',
        component: AppLayout,
        children: [
            {
                path: '/ps-customer-server/list',
                name: 'ps-customer-server.list',
                meta: {
                    breadcrumb: [{
                        title: 'PS Customer Server',
                        name: 'ps-customer-server.list'
                    }]
                },
                component: () => import('@/views/ps-customer-server/List.vue')
            },
            {
                path: '/ps-customer-server/add',
                name: 'ps-customer-server-add',
                meta: {
                    breadcrumb: [{
                        title: 'PS Customer Server',
                        name: 'ps-customer-server.list'
                    },
                    {
                        title: 'Add',
                        name: 'ps-customer-server-add'
                    }]
                },
                component: () => import('@/views/ps-customer-server/Add.vue')
            },
            {
                path: '/site-assessment/list',
                name: 'site-assessment.list',
                meta: {
                    breadcrumb: [
                        {
                            title: 'Site assessment',
                            name: 'site-assessment.list'
                        },
                    ]
                },
                component: () => import('@/views/site-assessment/List.vue')
            },
            {
                path: '/site-assessment/add',
                name: 'site-assessment.add',
                meta: {
                    breadcrumb: [
                        {
                            title: 'Site assessment',
                            name: 'site-assessment.list'
                        },
                        {
                            title: 'Add',
                            name: 'site-assessment.add'
                        }
                    ]
                },
                component: () => import('@/views/site-assessment/Add.vue')
            },
            {
                path: '/transformer/list',
                name: 'transformer.list',
                meta: {
                    breadcrumb: [
                        {
                            title: 'Transformer',
                            name: 'site-assessment.transformer'
                        },
                    ]
                },
                component: () => import('@/views/transformer/List.vue')
            },
            {
                path: '/solution-preferences/list',
                name: 'solution-preferences.list',
                meta: {
                    breadcrumb: [
                        {
                            title: 'Solution Preferences',
                            name: 'site-assessment.solution-preferences'
                        },
                    ]
                },
                component: () => import('@/views/solution-preferences/List.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/auth/Login.vue')
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/pages/404.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    }
});

export default router;
