<template>
  <splashLoader />
  <router-view />
  <Toast group="message" />
</template>

<script>
import splashLoader from './components/splashLoader.vue';

</script>
